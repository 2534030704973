<template>
  <div class="container mt-5">
    <h1 class="mb-4 text-center">Salesforce PDF Generator Tool</h1>
    
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="pdfType" class="form-label">Select PDF Type:</label>
          <select id="pdfType" v-model="pdfType" class="form-select" @change="updateFieldOptions">
            <option value="">Select a type</option>
            <option v-for="type in Object.keys(fieldsByType)" :key="type" :value="type">
              {{ type.charAt(0).toUpperCase() + type.slice(1) }}
            </option>
          </select>
        </div>
        
        <div v-if="pdfType" class="mb-3">
          <label for="fields" class="form-label">Select Fields:</label>
          <select id="fields" v-model="selectedFields" multiple class="form-control">
            <option v-for="field in fieldsByType[pdfType]" :key="field" :value="field">
              {{ field }}
            </option>
          </select>
        </div>
        
        <div class="mb-3">
          <label for="style" class="form-label">Select Style:</label>
          <select id="style" v-model="selectedStyle" class="form-select">
            <option v-for="style in Object.keys(styles)" :key="style" :value="style">
              {{ style.charAt(0).toUpperCase() + style.slice(1) }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <label for="companyName" class="form-label">Company Name:</label>
          <input type="text" id="companyName" v-model="companyName" class="form-control" placeholder="Enter company name">
        </div>

        <div class="mb-3">
          <label for="logoUrl" class="form-label">Logo URL:</label>
          <input type="text" id="logoUrl" v-model="logoUrl" class="form-control" placeholder="Enter logo URL">
        </div>
      </div>
      
      <div class="col-md-6">
        <div v-if="showPreview" id="previewArea" :style="styles[selectedStyle]">
          <div v-html="companyInfo"></div>
          <h3 class="preview-header">{{ objectName }} Information</h3>
          <table class="preview-content">
            <tr v-for="field in selectedFields" :key="field">
              <th>{{ field }}</th>
              <td>[{{ field }} value]</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    
    <div v-if="showPreview" id="template" class="mt-4">
      <h2>Visualforce Template:</h2>
      <pre id="vfTemplate" class="bg-light p-3 rounded">{{ visualforceTemplate }}</pre>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css';

export default {
  name: 'SalesforcePDFGenerator',
  setup() {
    const fieldsByType = {
      contact: ['FirstName', 'LastName', 'Email', 'Phone', 'Title', 'Department', 'MailingAddress', 'AccountId', 'OwnerId', 'CreatedDate', 'LastModifiedDate'],
      account: ['Name', 'Industry', 'Type', 'BillingAddress', 'Phone', 'Website', 'AnnualRevenue', 'NumberOfEmployees', 'OwnerId', 'CreatedDate', 'LastModifiedDate'],
      opportunity: ['Name', 'StageName', 'Amount', 'CloseDate', 'Probability', 'AccountId', 'OwnerId', 'Type', 'LeadSource', 'NextStep', 'Description'],
      lead: ['FirstName', 'LastName', 'Company', 'Email', 'Phone', 'Status', 'Rating', 'Industry', 'AnnualRevenue', 'NumberOfEmployees', 'LeadSource'],
      case: ['CaseNumber', 'Subject', 'Status', 'Priority', 'AccountId', 'ContactId', 'OwnerId', 'Type', 'Origin', 'Description', 'ClosedDate']
    }

    const styles = {
      classic: `
        body { font-family: Arial, sans-serif; color: #333; }
        .header { background-color: #f0f0f0; padding: 20px; border-bottom: 2px solid #999; }
        .content { padding: 20px; }
        h1 { color: #444; }
        table { width: 100%; border-collapse: collapse; }
        th, td { border: 1px solid #999; padding: 8px; text-align: left; }
        th { background-color: #f0f0f0; width: 30%; }
      `,
      modern: `
        body { font-family: 'Helvetica Neue', sans-serif; color: #2c3e50; }
        .header { background-color: #3498db; color: white; padding: 30px; }
        .content { padding: 30px; line-height: 1.6; }
        h1 { font-weight: 300; }
        table { width: 100%; border-collapse: collapse; }
        th, td { border: 1px solid #3498db; padding: 12px; text-align: left; }
        th { background-color: #3498db; color: white; width: 30%; }
      `,
      minimalist: `
        body { font-family: 'Roboto', sans-serif; color: #333; line-height: 1.6; }
        .header { border-bottom: 1px solid #eee; padding: 20px 0; }
        .content { padding: 30px 0; }
        h1 { font-weight: 100; color: #666; }
        table { width: 100%; border-collapse: collapse; }
        th, td { border: none; border-bottom: 1px solid #eee; padding: 10px; text-align: left; }
        th { font-weight: normal; color: #999; width: 30%; }
      `,
      colorful: `
        body { font-family: 'Open Sans', sans-serif; color: #333; background-color: #f9f9f9; }
        .header { background-color: #e74c3c; color: white; padding: 30px; }
        .content { padding: 30px; background-color: white; box-shadow: 0 0 10px rgba(0,0,0,0.1); }
        h1 { color: #2ecc71; }
        table { width: 100%; border-collapse: separate; border-spacing: 0 10px; }
        th, td { border: none; padding: 15px; text-align: left; }
        th { background-color: #3498db; color: white; width: 30%; }
        tr { background-color: #ecf0f1; }
      `
    }

    const pdfType = ref('')
    const selectedFields = ref([])
    const selectedStyle = ref('classic')
    const companyName = ref('')
    const logoUrl = ref('')

    const showPreview = computed(() => pdfType.value && selectedFields.value.length > 0)

    const objectName = computed(() => pdfType.value.charAt(0).toUpperCase() + pdfType.value.slice(1))

    const companyInfo = computed(() => {
      let html = ''
      if (companyName.value || logoUrl.value) {
        html += '<div style="text-align: center; margin-bottom: 20px;">'
        if (logoUrl.value) {
          html += `<img src="${logoUrl.value}" alt="Company Logo" style="max-width: 200px; max-height: 100px;"><br>`
        }
        if (companyName.value) {
          html += `<h2>${companyName.value}</h2>`
        }
        html += '</div>'
      }
      return html
    })

    const visualforceTemplate = computed(() => {
      if (!showPreview.value) return ''

      return `
<apex:page standardController="${objectName.value}" renderAs="pdf">
    <apex:stylesheet value="{!URLFOR($Resource.pdfStyles)}"/>
    <style>
        ${styles[selectedStyle.value]}
    </style>
    <div class="header">
        ${companyName.value ? `<h2 style="text-align: center;">${companyName.value}</h2>` : ''}
        ${logoUrl.value ? `<div style="text-align: center;"><img src="${logoUrl.value}" alt="Company Logo" style="max-width: 200px; max-height: 100px;"/></div>` : ''}
        <h1>${objectName.value} Information</h1>
    </div>
    <div class="content">
        <table>
            ${selectedFields.value.map(field => `
                <tr>
                    <th>${field}</th>
                    <td><apex:outputField value="{!${objectName.value}.${field}}"/></td>
                </tr>
            `).join('')}
        </table>
    </div>
</apex:page>`
    })

    const updateFieldOptions = () => {
      selectedFields.value = []
    }

    watch(pdfType, updateFieldOptions)

    return {
      fieldsByType,
      styles,
      pdfType,
      selectedFields,
      selectedStyle,
      companyName,
      logoUrl,
      showPreview,
      objectName,
      companyInfo,
      visualforceTemplate,
      updateFieldOptions
    }
  }
}
</script>

<style scoped>
.select2-container {
  width: 100% !important;
}
#previewArea {
  border: 1px solid #dee2e6;
  padding: 20px;
  margin-top: 20px;
}
#previewArea table {
  width: 100%;
  border-collapse: collapse;
}
#previewArea table, #previewArea th, #previewArea td {
  border: 1px solid #dee2e6;
}
#previewArea th, #previewArea td {
  padding: 8px;
  text-align: left;
}
</style>